import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const banner = {
    title: "70以上の言語サポート",
    tagline: "GDPR、CCPA、またはLGPDの要件を満たすために70以上の言語に対応"
}

export const features = {
    image: (<StaticImage title='70以上の言語サポート' src={"../../static/images/feature/70language-support/featureImage.png"} width={530} quality={100} alt="70 Language Support Feature Image"  />),
    contents: [
        {
            title: "多言語対応",
            paragraph: <p>Secure Privacyは、CCPA、GDPR、LGPDの全コンプライアンスプランで言語検出を行い、70以上の言語をサポートします。対応する言語は、英語、スペイン語、フランス語、ドイツ語、ポルトガル語、イタリア語、ロシア語、ノルウェー語、デンマーク語、オランダ語、クロアチア語、スウェーデン語、ルーマニア語、トルコ語、インド語、中国語、セルビア語、アイルランド語、ヘブライ語などです。</p>
        },
    ]
}

